<template>
  <section id="section-product">
    <div class="section-product__wrap">
      <div class="section__head section-product__head">
        <h3>PRODUCT</h3>
      </div>
      <div class="section-product__items">
        <div class="section-product__item server">
          <div class="section-product__item-left">
            <div class="section-product__item-img server">
              <img src="../../assets/lp_new/product1.png" alt="logo" />
            </div>
            <p class="section-product__item-name">GX FACTORY</p>
            <p class="section-product__item-desc">
              まるで小さなサプリメント工場。スポーツジムなどに設置した専用のサーバーでオリジナルの粉末サプリメントがその場でブレンド提供されます。専用のアプリを使い、学術情報を基にしたAI配合サポートや１グラム単位で素材量がカスタマイズできるなど、サプリメントに馴染みのない方からプロフェッショナルまで幅広いニーズに対応しています。もちろん提供素材は数々のエビデンスを基に精査・厳選し、安心の品質でお届け。どのような素材が合っているのか分からない不安、毎日のサプリメントの計量・持ち運びの手間から解放される体験は、皆様の運動・体づくりの環境を進化させます。
            </p>
          </div>
          <div
            class="section-product__item-right section-product__item-ingredient"
          >
            <p class="section-product__item-ingredient-label">
              GX FACTORY
            </p>
            <p class="section-product__item-ingredient-sub-label">
              ※価格など詳細はアプリまたは店頭パンフレットをご覧ください
            </p>
            <div class="section-product__item-ingredient-items">
              <div class="section-product__item-ingredient-item">
                <p>
                  EAA
                </p>
                <p>
                  1g 〜 20g
                </p>
              </div>
              <div class="section-product__item-ingredient-item">
                <p>
                  BCAA
                </p>
                <p>
                  1g 〜 20g
                </p>
              </div>
              <div class="section-product__item-ingredient-item">
                <p>
                  シトルリン&アルギニン
                </p>
                <p>
                  1g 〜 10g
                </p>
              </div>
              <div class="section-product__item-ingredient-item">
                <p>
                  クレアチン
                </p>
                <p>
                  1g 〜 10g
                </p>
              </div>
              <div class="section-product__item-ingredient-item">
                <p>
                  グルタミン
                </p>
                <p>
                  1g 〜 10g
                </p>
              </div>
              <div class="section-product__item-ingredient-item">
                <p>
                  オルニチン
                </p>
                <p>
                  1g 〜 10g
                </p>
              </div>
              <div class="section-product__item-ingredient-item">
                <p>
                  味（プレーン/グレープフルーツ/ミックスベリー）
                </p>
                <p>
                  薄〜濃
                </p>
              </div>
              <p class="section-product__item-ingredient-sub-label left">
                ※専用アプリにて1g単位で調整できます（味は薄〜濃の5段階）
              </p>
            </div>

            <p class="section-product__item-ingredient-label">
              アプリダウンロード
            </p>
            <div class="section-product__item-apps">
              <a
                class="ios"
                href="https://apps.apple.com/us/app/gold-experience/id1567523454"
              ></a>
              <a v-if="!this.isiOS"
                class="android"
                href="https://play.google.com/store/apps/details?id=jp.co.kirin.goldexperience.app"
              ></a>
            </div>
          </div>
        </div>
        <div class="section-product__item">
          <div class="section-product__item-left">
            <div class="section-product__item-img pack">
              <img src="../../assets/lp_new/product2_2.png" alt="logo" />
            </div>
            <p class="section-product__item-name">GX PACK MINI</p>
            <p class="section-product__item-desc">
              オールインワンのサプリメント。シーンや目的に合わせた最適なサプリメント。各種グレープフルーツ味とマンゴー味をご用意。
              GXBOOST（プレ・イントラワークアウトの決定版サプリメント）
              運動時の筋肉エネルギー源となるBCAA10g、一酸化窒素
              （NO）を相乗的に産生させるシトルリン・アルギニンを各1.5g配合（特許配合比）トレーニングに欠かせないクエン酸、ビタミンB群・Cを配合。
              GXCHARGE（ハードトレーニング向けサプリメント）
              タンパク質を構成する成分EAA（必須アミノ酸）12g、筋肉のエネルギー源として使用されるクレアチン、グルタミンを各5g配合。キリン独自素材”プラズマ乳酸菌”1,000億個配合。トレーニングに欠かせないクエン酸、ビタミンB群・Cを配合。
            </p>
          </div>
          <div
            class="section-product__item-right section-product__item-ingredient"
          >
            <p class="section-product__item-ingredient-label">
              GXBOOST<span>1袋（20g）当たり</span><span>648円（税込）</span>
            </p>
            <div class="section-product__item-ingredient-items">
              <div class="section-product__item-ingredient-item">
                <p>
                  BCAA
                </p>
                <p>
                  10g
                </p>
              </div>
              <div class="section-product__item-ingredient-item">
                <p>
                  シトルリン
                </p>
                <p>
                  1.5g
                </p>
              </div>
              <div class="section-product__item-ingredient-item">
                <p>
                  アルギニン
                </p>
                <p>
                  1.5g
                </p>
              </div>
            </div>

            <p class="section-product__item-ingredient-label">
              GXCHARGE<span>1袋（37g）当たり</span><span>756円（税込）</span>
            </p>
            <div class="section-product__item-ingredient-items">
              <div class="section-product__item-ingredient-item">
                <p>
                  EAA
                </p>
                <p>
                  12g
                </p>
              </div>
              <div class="section-product__item-ingredient-item">
                <p>
                  クレアチン
                </p>
                <p>
                  5g
                </p>
              </div>
              <div class="section-product__item-ingredient-item">
                <p>
                  グルタミン
                </p>
                <p>
                  5g
                </p>
              </div>
              <div class="section-product__item-ingredient-item">
                <p>
                  ”プラズマ乳酸菌”
                </p>
                <p>
                  1,000億個
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return { isiOS: this.$route.query.params == 'ios'}
  },
};
</script>

<style>
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */
/* section-product */
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */

#section-product {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #1a1a1a;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 70px 0 0;
}

.section-product__wrap {
  width: 100%;
  max-width: 1020px;
}

.section-product__items {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 30px auto 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.section-product__item {
  width: 100%;
  padding: 80px 0;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.section-product__item.server {
  border-bottom: solid 1px #a69c7d;
}

.section-product__item-left {
  width: 50%;
  margin-right: 15px;
}

.section-product__item-right {
  width: 50%;
  margin-left: 15px;
}

.section-product__item-img {
  margin: 0 auto 30px;
}

.section-product__item-img.server {
  width: 343px;
  height: 295px;
}

.section-product__item-img.pack {
  width: 252px;
  height: auto;
}

.section-product__item-img img {
  width: 100%;
}

.section-product__item-name {
  font-size: 24px;
  font-weight: bold;
}

.section-product__item-desc {
  font-size: 14px;
}

.section-product__item-ingredient-label {
  font-size: 18px;
  font-weight: bold;
  color: #a69c7d;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.section-product__item-ingredient-sub-label {
  font-size: 16px;
  font-weight: bold;
  color: #a69c7d;
  text-align: right;
}

.section-product__item-ingredient-sub-label.left {
  text-align: left;
}

.section-product__item-ingredient-items {
  margin-bottom: 50px;
}

.section-product__item-ingredient-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: #272727;
  padding: 15px 50px;
  margin: 7px auto;
  border-radius: 8px;
}

.section-product__item-ingredient-item p {
  color: #a69c7d;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.section-product__item-apps {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.section-product__item-apps a {
  height: 73px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

.section-product__item-apps a.ios {
  width: 198px;
  background-image: url("../../assets/lp_new/ios_download_img.png");
}

.section-product__item-apps a.android {
  width: 240px;
  background-image: url("../../assets/lp_new/android_download_img.png");
}

@media screen and (max-width: 1020px) {
  .section-product__items {
    margin: 0 auto;
  }

  .section-product__item {
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 80px 0;
  }

  .section-product__item.server {
    padding: 15px 0 80px;
  }

  .section-product__item-left {
    width: 100%;
    max-width: 700px;
    margin-right: 0px;
  }

  .section-product__item-right {
    width: 100%;
    max-width: 700px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 768px) {
  .section-product__wrap {
    padding: 0 24px;
  }

  #section-product {
    padding: 70px 0 0;
  }

  .section-product__head h3 br {
    display: none;
  }

  .section-product__item {
    padding: 50px 0;
  }

  .section-product__item.server {
    padding: 15px 0 50px;
  }

  .section-product__item-img.server {
    width: 210px;
    height: 181px;
  }

  .section-product__item-img.pack {
    width: 210px;
    height: auto;
  }
  .section-product__item-name {
    font-size: 18px;
  }

  .section-product__item-apps a {
    height: 50px;
  }

  .section-product__item-apps a.ios {
    width: 137px;
  }

  .section-product__item-apps a.android {
    width: 170px;
  }

  .section-product__item-ingredient-items {
    margin-bottom: 30px;
  }

  .section-product__item-ingredient-label {
    font-size: 14px;
  }

  .section-product__item-ingredient-item {
    padding: 10px 30px;
  }
}
</style>
